import logo from "assets/images/brands/logo.png";
import logoWhite from "assets/images/brands/logoWhite.png"; 

export const clientName = "Markets Act";
export const developedBy = "Markets Act";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002";
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN || "http://localhost:3001";
export const companyName =  "Markets Act";
export const companyWebsite = "https://marketsact.com/";
export const privacyPolicyLink = "pdfs/MABL_PRIVACY_POLICY.pdf";
export const termsLink = "pdfs/MABL_Website_T&Cs.pdf";
export const clientAgreementLink = "https://marketsact.com";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const darkLogo = logoWhite;
